<template>
  <!-- Modal de confirmation de renvoi -->
  <div class="modal fade" id="resendModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(250,250,250,0.4);">
            <h6 class="modal-title fw-bold ps-4">
              <i class="fas fa-paper-plane me-2"></i>
              {{ $t('messages.resend_confirmation') }}
            </h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body pb-5">
            <div class="message-details text-center">
              <i class="bi bi-exclamation-triangle-fill text-warning fs-2 mb-3"></i>
              <h6>{{ $t('messages.confirm_resend_campaign') }}</h6>
              <table class="table table-striped table-hover" style="border:1px solid rgba(255, 255, 255,0.4)">
                <thead>
                  <tr>
                    <th class="text-start border" v-if="selectedMessage.fr_title">{{ $t('messages.title_fr') }}</th>
                    <th class="border" v-if="selectedMessage.en_title">{{ $t('messages.title_en') }}</th>
                    <th class="text-end">{{ $t('messages.classroom') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="message-row">
                    <td class="ps-4 text-start border" v-if="selectedMessage.fr_title">{{ selectedMessage.fr_title }}
                    </td>
                    <td class="ps-4 border" v-if="selectedMessage.en_title">{{ selectedMessage.en_title }}</td>
                    <td class="pe-4 text-end last_td">
                      {{ selectedMessage?.criteria?.grade_code }}
                      <span v-if="selectedMessage?.criteria?.number">/</span>
                      {{ selectedMessage?.criteria?.number }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="modal-footer" style="border-top:1px solid rgba(255, 255, 255, 0.4);">
            <argon-button type="button" color="danger" variant="gradient" class="btn" data-bs-dismiss="modal"
              style="width: 150px; height: 50px; padding-top: 15px">
              {{ $t("button.cancel") }}
            </argon-button>
            <argon-button :localLoading="localLoading" :active="buttonActive || showConfirmation" color="success"
              variant="gradient" type="button" class="mt-1" style="width: 150px; height: 50px; padding-top: 15px"
              @click="confirmResend">
              {{ $t("button.confirm") }}
            </argon-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container w-80" style="margin-top: 80px;">
    <div class="top">
      <h6>{{ $t('messages.sent_messages') }}</h6>
    </div>

    <!-- Tableau des messages -->
    <div>
      <div v-if="isLoading" class="text-center my-5">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">{{ $t('messages.loading') }}</span>
        </div>
      </div>

      <div v-else-if="!campaigns.length" class="text-center my-5">
        <img class="image fs-4" src="@/assets/img/message.png" alt="no_message" />
        <h5 class="text-muted mt-n5">{{ $t('messages.no_messages_found') }}</h5>
        
      </div>

      <table v-else class="table table-striped table-hover" style="border:1px solid rgba(255, 255, 255,0.4)">
        <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('messages.title_fr') }}</th>
            <th>{{ $t('messages.title_en') }}</th>
            <th>{{ $t('messages.classroom') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(message, index) in paginatedMessages" :key="message.id" class="message-row">
  <td class="ps-4">{{ index + 1 + (currentPage - 1) * itemsPerPage }}</td>
  <td class="ps-4">{{ message.fr_title }}</td>
  <td class="ps-4">{{ message.en_title }}</td>
  <td class="pe-4 text-end last_td">
    {{ message.criteria.grade_code }}
    <span v-if="message.criteria.number">/</span>
    {{ message.criteria.number }}
  </td>
  <!-- Ajouter une nouvelle cellule pour les icônes d'action -->
  <td class="position-relative">
    <span class="action-icons border-0">
      <i 
        :title="$t('messages.view_campaign_details')" 
        class="fas fa-eye" 
        data-bs-toggle="modal"
        data-bs-target="#exampleModal34" 
        @click="viewDetails(message)"
      ></i>
      <i 
        :title="$t('messages.resend_campaign')" 
        data-bs-toggle="modal" 
        data-bs-target="#resendModal"
        class="fas fa-history" 
        @click="resendCampaign(message)"
      ></i>
    </span>
  </td>
</tr>
        </tbody>
      </table>

      <!-- Pagination -->
      <nav aria-label="Pagination" class="text-start" v-if="campaigns.length > 0">
        <ul class="pagination justify-content-start mt-5">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="changePage(currentPage - 1)">
              <i class="fas fa-chevron-left"></i>
            </button>
          </li>
          <li v-for="page in totalPages" :key="page" class="page-item" :class="{ active: currentPage === page }">
            <button class="page-link" @click="changePage(page)">{{ page }}</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="changePage(currentPage + 1)">
              <i class="fas fa-chevron-right"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="exampleModal34" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="card">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: 1px solid rgba(250,250,250,0.1);">
            <h6 class="modal-title fw-bold ps-4" id="exampleModalLabel">
              <i class="fas fa-envelope-open-text me-2"></i>
              {{ $t('messages.message_details') }}
            </h6>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">
            <div class="message-details">
              <div class="row">
                <div class="col-md-6" v-if="selectedMessage.fr_title">
                  <div class="detail-card">
                    <h6 class="detail-title"><i class="fas fa-language me-2"></i>{{ $t('messages.french_title') }}</h6>
                    <p class="detail-content">{{ selectedMessage.fr_title }}</p>
                  </div>
                </div>
                <div class="col-md-6" v-if="selectedMessage.en_title">
                  <div class="detail-card">
                    <h6 class="detail-title"><i class="fas fa-language me-2"></i>{{ $t('messages.english_title') }}</h6>
                    <p class="detail-content">{{ selectedMessage.en_title }}</p>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-12" v-if="selectedMessage.fr_body">
                  <div class="detail-card">
                    <h6 class="detail-title"><i class="fas fa-comment-alt me-2"></i>{{ $t('messages.french_message') }}
                    </h6>
                    <div class="message-content" v-html="selectedMessage.fr_body"></div>
                  </div>
                </div>
                <div class="col-md-12" v-if="selectedMessage.en_body">
                  <div class="detail-card">
                    <h6 class="detail-title"><i class="fas fa-comment-alt me-2"></i>{{ $t('messages.english_message') }}
                    </h6>
                    <div class="message-content" v-html="selectedMessage.en_body"></div>
                  </div>
                </div>
              </div>

              <div v-if="selectedMessage.media" class="attachment-section mt-4 text-start">
                <h6 class="detail-title"><i class="fas fa-paperclip me-2"></i>{{ $t('messages.attachment') }}</h6>

                <!-- Image preview -->
                <img v-if="isImage(selectedMessage.media)" :src="selectedMessage.media" class="media-preview"
                  :alt="$t('messages.attached_image')">

                <!-- Video preview -->
                <video v-if="isVideo(selectedMessage.media)" class="media-preview" controls>
                  <source :src="selectedMessage.media" :type="getVideoType(selectedMessage.media)">
                </video>
              </div>
            </div>
          </div>

          <div class="modal-footer" style="border-top: 1px solid rgba(250,250,250,0.1);">
            <button type="button" class="btn btn-secondary me-3" data-bs-dismiss="modal" style="height: 45px;">
              <i class="fas fa-times me-2"></i>{{ $t('button.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Campaigns } from "@/utils/services";
import ArgonButton from "@/components/ArgonButton";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
export default {

  name: "MessagesTable",
  props: {
    campaigns: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ArgonButton
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 5,
      selectedMessage: {},
      localLoading: false,
      isSubmitting: false,
      buttonActive: false,
      showConfirmation: false,

    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.campaigns.length / this.itemsPerPage);
    },
    paginatedMessages() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      // Reverse the array to show newest first
      return [...this.campaigns].reverse().slice(start, end);
    },
    hasClassInfo() {
      return this.selectedMessage?.criteria?.grade_code || this.selectedMessage?.criteria?.number;
    }
  },
  methods: {

    isImage(url) {
      return /\.(jpg|jpeg|png|gif|webp)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    getVideoType(url) {
      const ext = url.split('.').pop().toLowerCase();
      return `video/${ext}`;
    },
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    viewDetails(message) {
      this.selectedMessage = message;
    },

    resendCampaign(message) {
      this.selectedMessage = message;

    },

    async confirmResend() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.localLoading = true;

      try {
        const campaignData = {
          en_title: this.selectedMessage.en_title,
          fr_title: this.selectedMessage.fr_title,
          en_body: this.selectedMessage.en_body,
          fr_body: this.selectedMessage.fr_body,
          media: this.selectedMessage.media || "",
          criteria: this.selectedMessage.criteria
        };

        await Campaigns(campaignData);

        if (!this.showConfirmation) {
          this.showConfirmation = true;
          toast.success("Campagne renvoyée avec succès!", {
            autoClose: 3000
          });



          document.body.classList.remove("modal-open");
          const modalBackdrops =
            document.getElementsByClassName("modal-backdrop");
          for (const backdrop of modalBackdrops) {
            backdrop.parentNode.removeChild(backdrop);
          }

          setTimeout(() => {
            this.showConfirmation = false;
            window.location.reload();
            this.showConfirmation = false;
          }, 4000);
        }





      } catch (error) {
        console.error("Erreur lors du renvoi de la campagne:", error);
        toast.error("Échec du renvoi de la campagne. Veuillez réessayer.");
      } finally {
        this.localLoading = false;
        this.isSubmitting = false;
      }
    }

  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.modal-header {
  border-radius: 15px 15px 0 0;
  padding: 1.5rem;
}

.message-details {
  padding: 20px;
}

.detail-card {
  border-radius: 10px;
  padding: 15px;
  height: 100%;

}

.detail-title {
  color: #2c3e50;
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 1rem;

}

.detail-content {
  color: #34495e;
  margin-bottom: 0;
  border: 1px solid rgba(250, 250, 250, 0.1);
  border-radius: 8px;
  padding: 10px;

}

.message-content {
  padding: 10px;
  border-radius: 8px;
  min-height: 100px;
  border: 1px solid rgba(250, 250, 250, 0.1);
}

.stats-section {
  border-radius: 10px;
  padding: 20px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.stat-item {
  background: white;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stat-label {
  display: block;
  color: #6c757d;
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: 600;
}

.attachment-section {
  border-radius: 10px;
  padding: 20px;
}

.modal-footer {
  border-top: none;
  padding: 1.5rem;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
  padding: 8px 20px;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.message-row {
  position: relative;
  cursor: pointer;
}

.action-icons {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.message-row:hover .action-icons {
  display: flex;
  gap: 15px;
}

.message-row:hover .last_td {
  filter: blur(4px);
}

.action-icons i {
  color: green;
  cursor: pointer;
  font-size: 18px;
}

.action-icons i:hover {
  color: darkgreen;
}

.message-row {
  position: relative;
  cursor: pointer;
}



.top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.table {
  margin-top: 20px;
}

.action-btn {
  color: green;
  cursor: pointer;
  font-size: 18px;
}

.action-btn:hover {
  color: darkgreen;
}

.pagination {
  margin-top: 20px;
}

.media-preview {
  max-width: 100%;
  max-height: 300px;
  border-radius: 8px;
  margin: 10px 0;
  object-fit: contain;
}

.attachment-section {
  text-align: center;
}

.action-icons {
  display: none;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

/* Ajuster la position relative pour la nouvelle cellule */
.position-relative {
  position: relative;
  width: 100px; /* Ajustez selon vos besoins */
}

/* Le reste du CSS reste inchangé */
</style>
