<template>
    <div class="card w-70 mx-auto shadow-sm p-4">
        <div class="content text-center">
            <i class="fas fa-award text-success mb-3" style="font-size: 3.5rem;"></i>
            <h4 class="modal-title mt-4 mb-3 fw-bold">{{ $t("sponsoring.title_alert") }}</h4>
            <p class="text-muted">{{ $t("sponsoring.body_alert") }}</p>
            <div class="mt-4">
                <i class="fas fa-check-circle text-success me-2"></i>
                <span>{{ $t("sponsoring.bottom_alert") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'School_sponsorised',
    data() {
        return {
            school_name: "",
            xpay_connection_id: localStorage.getItem('xpay_connection_id')
        }
    }
}
</script>

<style scoped>
.card {
    border-radius: 12px;
    transition: all 0.3s ease;
    max-width: 700px;
    margin-top: 2rem;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.content {
    animation: fadeIn 0.6s ease-out;
}
</style>
