<template>
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h6 class="modal-title" style="margin-left: 13%;" id="staticBackdropLabel10">
                        {{ $t("grade_available.title") }} {{ currentStep }}/{{ totalSteps }}


                    </h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" @click="annuler"
                        aria-label="Close"></button>
                </div>

                <div class="modal-body text-center pt-4 pb-4">
                    <div class="step-indicator mb-4">
                        <div class="d-flex justify-content-center">
                            <div v-for="step in totalSteps" :key="step"
                                :class="['step-dot mx-2', { active: step === currentStep }]">
                                {{ step }}
                            </div>
                        </div>
                    </div>
                    <div class="container mt-3 mb-5">
                        <form class="mx-auto needs-validation" style="width:80%;" @submit.prevent="sendNoteAvailability"
                            novalidate>
                            <!-- Step 1: Sequences -->
                            <div v-show="currentStep === 1">
                                <div class="mb-3 text-start">
                                    <p>
                                        {{ $t("grade_available.description") }}
                                    </p>
                                    <label for="sequences" style="font-size:14px" class="form-label">
                                        {{ $t("grade_available.label1") }} <em style="color: #f60000">*</em>
                                    </label>
                                    <div class="row mt-3 ps-3">
                                        <div v-for="sequence in sequences" :key="sequence.id"
                                            class="form-check col-3 mt-2">
                                            <input class="form-check-input" type="radio" name="sequence"
                                                :id="`sequence-${sequence.id}`" :value="sequence.name"
                                                v-model="selectedSequence" required
                                                :class="{ 'is-invalid': formSubmitted && !selectedSequence }">
                                            <label class="form-check-label" :for="`sequence-${sequence.id}`">
                                                {{ sequence.name }}
                                            </label>
                                        </div>
                                        <!-- Add this validation message -->
                                        <div class="invalid-feedback d-block" v-if="formSubmitted && !selectedSequence">
                                            {{ $t("grade_available.error1") }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Step 2: Matieres -->
                            <div v-show="currentStep === 2">
                                <div class="mb-3 text-start">
                                    <label for="matieres" style="font-size:14px" class="form-label">
                                        {{ $t("grade_available.label2") }} <em style="color: #f60000">*</em>
                                    </label>
                                    <div class="row mt-3 ps-3" v-if="matieres">
                                        <div v-for="matiere in matieres" :key="matiere.id"
                                            class="form-check col-3 mt-2">
                                            <input class="form-check-input" type="checkbox"
                                                :id="`matiere-${matiere.id}`" :value="matiere.name"
                                                v-model="selectedMatieres" required
                                                :class="{ 'is-invalid': formSubmitted && selectedMatieres.length === 0 }">
                                            <label class="form-check-label" :for="`matiere-${matiere.id}`"
                                                :title="matiere.name">
                                                {{ matiere.name.length > 12 ? matiere.name.substring(0, 12) + '...' :
                                                matiere.name }}
                                            </label>
                                        </div>

                                        <div class="invalid-feedback d-block"
                                            v-if="formSubmitted && selectedMatieres.length === 0">
                                            {{ $t("grade_available.error2") }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button v-if="currentStep > 1" @click="previousStep" type="button" class="btn"
                        title="Précédent"
                        style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
                        <span>{{ $t("button.previous") }}</span>
                    </argon-button>
                    <argon-button v-if="currentStep < totalSteps" @click="nextStep" type="button" class="btn me-7"
                        color="success" variant="gradient" style="width: 200px; height: 50px; padding-top: 15px"
                        :disabled="!canProceedToNextStep">
                        <span>{{ $t("button.next") }}</span>
                    </argon-button>
                    <argon-button v-if="currentStep === totalSteps" @click="sendNoteAvailability" type="button"
                        class="btn me-7" color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isFormValid">
                        <span v-if="!loading">{{ $t("button.send") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { postNoteAvailable, getSubjects, getAlertSubscribers } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'Note-Modal',
    components: {
        ArgonButton
    },
    props: {
        gradeCode: {
            type: String,
            required: true
        },
        classNumber: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            currentStep: 1,
            totalSteps: 2,
            loading: false,
            buttonActive: false,
            showConfirmation: false,
            formSubmitted: false,
            registrationNumber: [],
            matieres: [],
            sequences: [
                { id: 1, name: this.$t('grade_available.seq1') },
                { id: 2, name: this.$t('grade_available.seq2') },
                { id: 3, name: this.$t('grade_available.seq3') },
                { id: 4, name: this.$t('grade_available.seq4') },
                { id: 5, name: this.$t('grade_available.seq5') },
                { id: 6, name: this.$t('grade_available.seq6') }
            ],
            selectedSequence: '',
            selectedMatieres: [],
            classroomId: null
        };
    },
    computed: {
        canProceedToNextStep() {
            switch (this.currentStep) {
                case 1:
                    return Boolean(this.selectedSequence);
                case 2:
                    return this.selectedMatieres.length > 0;
                default:
                    return true;
            }
        },
        isFormValid() {
            return this.selectedSequence && this.selectedMatieres.length > 0;
        }
    },
    mounted() {
        this.school_id = localStorage.getItem("schoolId");
        this.fetchSubjects();
        this.fetchRegistrationNumbers();
    },
    methods: {
        annuler() {
            this.selectedMatieres = [];
            this.selectedSequence = '';
            this.formSubmitted = false;
            this.currentStep = 1;
        },


        async fetchSubjects() {
            const country_code = "CM";
            const education_grade = localStorage.getItem("education_grade");
            const training_type = localStorage.getItem("training_type");
            const education_system =
                localStorage.getItem("education_system") === "Bilingual"
                    ? ""
                    : localStorage.getItem("education_system");

            if (!education_grade || !training_type) {
                this.error = "Certains paramètres sont manquants.";
                return;
            }

            try {
                const subjects = await getSubjects(
                    country_code,
                    education_grade,
                    training_type,
                    education_system
                );

                // Use Map to keep track of unique names
                const uniqueSubjects = Array.from(
                    new Map(subjects.map(item => [item.name, item])).values()
                );

                // Transform the filtered results
                this.matieres = uniqueSubjects.map((subject) => ({
                    id: subject.id,
                    name: subject.name,
                }));

                console.log("Matières transformées :", this.matieres);
            } catch (err) {
                console.error("Erreur lors de la récupération des sujets :", err);
                this.error = "Erreur lors de la récupération des sujets.";
            }
        },





        nextStep() {
            this.formSubmitted = true;

            if (this.currentStep === 1 && !this.selectedSequence) {
                return; // Stop here if no sequence selected
            }

            this.formSubmitted = false;

            if (this.currentStep < this.totalSteps) {
                this.currentStep++;
            }

        },

        previousStep() {
            if (this.currentStep > 1) {
                this.currentStep--;
            }
        },


        async fetchRegistrationNumbers() {

            try {
                const response = await getAlertSubscribers(this.gradeCode, this.classNumber, 'ESKAALERT');

                if (response.status === 200) {
                    this.registrationNumber = response.data;

                } else {
                    toast.error("Erreur lors de la récupération des matricules des élèves", {
                        autoClose: 3000,
                    });
                }
            } catch (error) {
                toast.error("Erreur lors de la récupération des matricules des élèves", {
                    autoClose: 3000,
                });
            }
        },



        async sendNoteAvailability() {
            this.formSubmitted = true;

            if (!this.selectedSequence || this.selectedMatieres.length === 0) {
                return;
            }

            if (this.loading) {
                return;
            }
            this.loading = true;
            this.buttonActive = true;

            try {

                await this.fetchRegistrationNumbers();
                const data = {
                    school_id: this.school_id,
                    code: 4100,
                    registration_number_list: this.registrationNumber,
                    sequence: this.selectedSequence.toLowerCase().replace(' ', ''),
                    subject_list: this.selectedMatieres
                };

                const response = await postNoteAvailable(data);

                if (response.status === 200) {
                    if (!this.showConfirmation) {
                        this.showConfirmation = true;
                        const message = this.$t("grade_available.success_message");
                        toast.success(message, {
                            autoClose: 3000,
                        });

                        this.annuler();
                    }
                }
            } catch (error) {
                toast.error("Erreur lors de l'envoi de la notification", {
                    autoClose: 3000,
                });
            } finally {
                this.loading = false;
                this.buttonActive = false;
                this.showConfirmation = false;
            }
        }
    }
};
</script>

<style>
#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}

.invalid-feedback {
    color: #dc3545;
    font-size: 0.875em;
    margin-top: 0.25rem;
}
</style>
