<template>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="card">
                <div class="modal-header mb-4" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h6 style="margin-left: 19%;" class="modal-title">{{ $t("comportement.title") }} </h6>
                    <button type="button" @click="resetForm" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body border-0">
                    <div class="tab-content mt-3 mb-4">
                        <div class="container">
                            <form class=" w-80 mx-auto">
                                <div class="container ">
                                    <!-- Date -->
                                    <div class="form-group  mx-auto mb-4 w-90">
                                        <label>{{ $t("comportement.date") }} <em style="color: #f60000">*</em></label>
                                        <input id="input" type="date" class="form-control" v-model="currentDate">
                                    </div>

                                    <div class="row">
                                        <div class="form-group mb-4 col-md-6">
                                            <label style="margin-left: 11%;">{{ $t("comportement.presence") }} </label>
                                            <div class="d-flex gap-3 mx-auto  w-80">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" v-model="presence"
                                                        value="oui" id="presenceOui">
                                                    <label class="form-check-label" for="presenceOui">{{
                                                        $t("comportement.yes") }} </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" v-model="presence"
                                                        value="non" id="presenceNon">
                                                    <label class="form-check-label" for="presenceNon">{{
                                                        $t("comportement.no") }} </label>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="form-group mb-4 col-md-6">
                                            <label>{{ $t("comportement.punctuality") }} </label>
                                            <div class="d-flex gap-3">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" v-model="ponctualite"
                                                        value="oui" id="ponctualiteOui" :disabled="isAbsent">
                                                    <label class="form-check-label" for="ponctualiteOui">{{
                                                        $t("comportement.yes") }} </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="radio" v-model="ponctualite"
                                                        value="non" id="ponctualiteNon" :disabled="isAbsent">
                                                    <label class="form-check-label" for="ponctualiteNon">{{
                                                        $t("comportement.no") }} </label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row">


                                        <div class="form-group mb-4 col-md-6">
                                            <label style="margin-left: 11%;">{{ $t("comportement.attitude") }} </label>
                                            <div class="stars d-flex gap-2  mx-auto  w-80">
                                                <i v-for="n in 5" :key="'attitude-' + n"
                                                    :class="['fas fa-star', { active: n <= attitudeRating }, { 'disabled-star': isAbsent }]"
                                                    @click="!isAbsent && rateAttitude(n)">
                                                </i>
                                            </div>
                                            <div style="margin-left: 10%;" class="attitude-message mt-2">{{
                                                attitudeMessage }}</div>
                                            <div style="margin-left: 10%;" class="invalid-feedback"
                                                v-if="formSubmitted && !attitudeRating">
                                                {{ $t("comportement.attitude_required") }}
                                            </div>
                                        </div>

                                        <!-- Intérêt (étoiles) -->
                                        <div class="form-group mb-4 col-md-6">
                                            <label>{{ $t("comportement.interest") }} </label>
                                            <div class="stars d-flex gap-2">
                                                <i v-for="n in 5" :key="'interet-' + n"
                                                    :class="['fas fa-star', { active: n <= interetRating }, { 'disabled-star': isAbsent }]"
                                                    @click="!isAbsent && rateInteret(n)">
                                                </i>
                                            </div>
                                            <div class="interet-message mt-2">{{ interetMessage }}</div>
                                            <div class="invalid-feedback" v-if="formSubmitted && !interetRating">
                                                {{ $t("comportement.interest_required") }}
                                            </div>

                                        </div>

                                    </div>
                                    <!-- Attitude (étoiles) -->

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal" @click="resetForm"
                        style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
                        <span>
                            {{ $t("button.cancel") }}
                        </span>
                    </argon-button>
                    <argon-button @click="sendAlert" type="button" class="btn me-5" color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :loading="loading"
                        :active="buttonActive || showConfirmation" :clickEvent="button">
                        <span v-if="!loading">{{ $t("button.send") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { postNoteAvailable } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
    name: 'Modal_Discipline',
    components: {
        ArgonButton
    },
    data() {
        return {
            selectedMotifs: [],
            alertDate: '',
            alertTime: '',
            actionsTaken: '',
            parentSuggestions: '',
            currentDate: '',
            presence: 'oui',
            ponctualite: 'non',
            attitudeRating: 0,
            interetRating: 0,

            loading: false,
            button: '',
            buttonActive: false,
            showConfirmation: false,
            formSubmitted: false,
            school_id: ''
        }
    },
    mounted() {
        this.currentDate = this.getCurrentDate();
        this.school_id = localStorage.getItem("schoolId");
    },

    props: {
        registrationNumber: {
            type: String,
            required: true
        },

    },
    methods: {
        getCurrentDate() {
            const today = new Date();
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const dd = String(today.getDate()).padStart(2, '0');
            return `${yyyy}-${mm}-${dd}`;
        },
        rateAttitude(rating) {
            this.attitudeRating = rating;
        },
        rateInteret(rating) {
            this.interetRating = rating;
        },

        resetForm() {
            this.currentDate = this.getCurrentDate();
            this.presence = 'oui';
            this.ponctualite = 'oui';
            this.attitudeRating = 0;
            this.interetRating = 0;
            this.formSubmitted = false;
        },

        async sendAlert() {
            this.formSubmitted = true;

            if (this.loading) {
                return;
            }

            if (!this.currentDate || !this.presence || !this.ponctualite || !this.attitudeRating || !this.interetRating) {

                return;
            }

            this.loading = true;
            this.button = "disabled";
            this.buttonActive = true;

            try {
                const data = {
                    school_id: this.school_id,
                    code: 4103,
                    registration_number_list: [this.registrationNumber],
                    date: this.currentDate,
                    attendance: this.presence,
                    punctuality: this.ponctualite,
                    attitude: this.attitudeMessage,
                    engagement: this.interetMessage
                };

                const response = await postNoteAvailable(data);

                if (response.status === 200) {
                    if (!this.showConfirmation) {
                        this.showConfirmation = true;
                        const message = this.$t("comportement.success_message");
                        toast.success(message, {
                            autoClose: 3000,
                        });

                    }

                    this.resetForm();
                }
            } catch (error) {
                toast.error("Erreur lors de l'envoi du rapport", {
                    autoClose: 3000,
                });
            } finally {
                this.loading = false;
                this.button = "";
                this.buttonActive = false;
                this.showConfirmation = false;
            }
        }

    },
    computed: {
        isChoiceMade() {
            return this.presence && this.ponctualite && this.attitudeRating > 0 && this.interetRating > 0;
        },
        attitudeMessage() {
            switch (this.attitudeRating) {
                case 1: return this.$t('attitude.veryPoor');
                case 2: return this.$t('attitude.needsImprovement');
                case 3: return this.$t('attitude.average');
                case 4: return this.$t('attitude.good');
                case 5: return this.$t('attitude.excellent');
                default: return this.$t('attitude.pleaseRate');
            }
        },

        interetMessage() {
            switch (this.interetRating) {
                case 1: return this.$t('interest.veryLow');
                case 2: return this.$t('interest.low');
                case 3: return this.$t('interest.average');
                case 4: return this.$t('interest.high');
                case 5: return this.$t('interest.veryHigh');
                default: return this.$t('interest.pleaseRate');
            }
        },
        isAbsent() {
        return this.presence === 'non';
    }

    }
}
</script>

<style scoped>

 
.disabled-star {
    opacity: 0.5;
    cursor: not-allowed !important;
}

input:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.modal.show {
    display: block;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid rgba(250, 250, 250, 0.4);
    border-radius: 5px;
    color: white;
}

.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #75acff;
    border: 1px solid #75acff;
}

.modal-lg {
    max-width: 800px;
}

.stars i {
    cursor: pointer;
    color: #ccc;
    font-size: 1.5em;
}

.stars i.active {
    color: #ffd700;
}

.attitude-message,
.interet-message {
    font-size: 0.9em;
    color: #75acff;
}

.form-group label {
    margin-bottom: 0.5rem;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}


.is-invalid {
    border-color: #dc3545 !important;
}

.invalid-rating {
    border: 1px solid #dc3545;
    border-radius: 4px;
    padding: 5px;
}

.invalid-feedback {
    display: block;
    color: #dc3545;
    font-size: 0.875em;
    margin-top: 0.25rem;
}
</style>
