<template>
    <div class="card w-70 mx-auto">
        <div class="content text-center">
            <i class="fas fs-1 fa-exclamation-triangle text-warning mb-2" style="font-size: 2rem;"></i>
            <h5 class="modal-title mt-4 mb-3">{{ $t("transfer.modal_bank_title") }}</h5>
            <p>{{ $t("transfer.modal_bank_body") }}</p>
            <a :href="`https://cm.xschoolpay.eskalearning.com/enroll/${xpay_connection_id}/`" target="_blank" class="btn btn-primary mt-3"
                style="height: 50px;padding-top: 15px;" @click="close1">
                {{ $t("transfer.modal_bank_button") }}
            </a>
        </div>
    </div>
</template>




<script>

export default {
    name: 'Bank-Doc',
    data() {
        return {
            school_name: "",
            xpay_connection_id: localStorage.getItem('xpay_connection_id')
        }
    },

    components: {

    },
    
    props: {
        amount: {
            type: Boolean,
            required: true
        },

        installmentNumber: {
            type: Boolean,
            required: true
        },

        due_date: {
            type: Boolean,
            required: true
        },
        close1: {
            type: Function,
            required: true,
        },

        sendinvoice: {
            type: Function,
            required: true,
        },
        refreshComponentData: {
            type: Function,
            required: true,
        }
    },
    methods: {




    }

}
</script>

<style>
@keyframes fadeOut {
    0% {
        opacity: 0;
        transform: translateY(0);
    }

    100% {
        opacity: 4;
        transform: translateY(60px);
        /* L'élément monte légèrement et disparaît */
    }
}

@keyframes resetPosition {
    0% {
        opacity: 1;
        transform: translateY(100%);
        /* Positionne l'élément en bas */
    }

    100% {
        opacity: 4;
        transform: translateY(0);
        /* L'élément réapparaît à sa position de départ */
    }
}
</style>
