<template>

   <!--========================= modal sequence alert =================================== -->


   <div class="modal fade" id="staticBackdrop51" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <SequenceModal/>

  </div>

  <!--========================= modal stop course alert =================================== -->


  <div class="modal fade" id="staticBackdrop52" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">

    <StopcourseModal />

  </div>

  <div class=" container-fluid" >
     
      
    <div class="row">
       
      <div class="col-lg-12  py-0">

        <div class="row items mt-n1">
          <div class="row d-flex justify-content-end" >
            <div class="col-md-3 ">
              <div class="container d-flex mt-3 ">
                <div class="legend d-flex" v-if="selectedOption === 'default'" >
                  <span class="lg1 bg-gradient-warning" > </span> <span class="mx-2">{{ $t("classroom.code_pending") }}</span>
                </div>
                <div class="legend d-flex" style="margin-left: 20px" v-if="selectedOption === 'default'" >
                  <span class="lg2 bg-gradient-danger"></span> <span class="mx-2"> {{ $t("classroom.code_overdue") }}</span>
                </div>

                <div class="legend d-flex" v-if="selectedOption === 'option1'">
                  <h5>{{ $t("classroom.code_pending") }} </h5>
                </div>
                <div   class="legend d-flex" v-if="selectedOption === 'option3'" >
                  <h5>{{ $t("classroom.code_overdue") }}  </h5>
                </div>
 
                
              </div>
            </div>

            
            <div class="col-md-9  px-0">
              <div class="action-buttons mt-3 d-flex flex-wrap justify-content-end align-items-center gap-3">
                <!-- Alert Dropdown -->
                <div class="dropdown">
                  <button class="btn dropdown-toggle text-light fw-bold" type="button" data-bs-toggle="dropdown">
                    <span class="icon-alert me-2">
                      <i class="text-light bi bi-lightning-charge-fill"></i>
                    </span>
                    {{ $t("studentlist.alert") }}
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticBackdrop51">
                      {{$t("studentlist.sequence_date") }}
                    </li>
                    <li class="dropdown-item" data-bs-toggle="modal" data-bs-target="#staticBackdrop52">
                      {{$t("studentlist.course_suspension") }}
                    </li>
                  </ul>
                </div>

                <!-- Filter Dropdown -->
                <div class="dropdown">
                  <button class="btn dropdown-toggle text-light fw-bold" type="button" data-bs-toggle="dropdown">
                    <i class="icon-filter text-success fa fa-sliders me-2"></i>
                    {{$t("classroom.filter") }}
                  </button>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item" @click="selectOption('default')">
                      {{ $t("classroom.filter_option1") }}
                    </li>
                    <li class="dropdown-item" @click="selectOption('option1')">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ $t("classroom.filter_option2") }}</span>
                        <span class="badge bg-warning">{{ pendingCount }}</span>
                      </div>
                    </li>
                    <li class="dropdown-item" v-show="false" @click="selectOption('option3')">
                      <div class="d-flex justify-content-between align-items-center">
                        <span>{{ $t("classroom.filter_option3") }}</span>
                        <span class="badge bg-danger">{{ overdueCount }}</span>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- New Class Button -->
                <router-link :to="{ name: 'new-class' }" class="btn bg-success text-light">
                  <span class="fa fa-plus-circle me-2"></span>
                  {{ $t("classroom.button_new_class") }}
                </router-link>
              </div>
          </div>
          </div>


          <div class="mt-n4" v-if="selectedOption === 'default'">
            <classes-list-card />
          </div>
          <div class="mt-n4" v-if="selectedOption === 'option1'">
            <classes-list-card2 />
          </div>
          <div class="mt-n4"  v-if="selectedOption === 'option3'">
            <classes-list-card3 />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getdashboardreport } from "@/utils/services";
import ClassesListCard from "@/views/Classroom/ClassListCard";
import ClassesListCard2 from "@/views/Classroom/ClassListCard2";
import ClassesListCard3 from "@/views/Classroom/ClassListCard3";
import SequenceModal from "../modal/sequencemodal.vue"
import StopcourseModal from "../modal/stopcoursemodal.vue"
 

export default {
  name: "school-classes",
  data() {
    return {
      selectedOption: 'default',
      isOffline: !navigator.onLine, 
      pending_count:0,
      pendingCount: 0,
      overdueCount: 0,
      isLocal: window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1',
    };
  },
  components: {
    ClassesListCard,
    ClassesListCard2,
    ClassesListCard3,
    SequenceModal,
    StopcourseModal,
    
    

  },
  mounted() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.intervalId = setInterval(this.checkInternetConnection, 1000);
    this.fetchDashboardData();


  },

 
  beforeUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },


  methods: {

    selectOption(option) {
      this.selectedOption = option;

    },

    async fetchDashboardData() {
      try {
        this.loading = true;
        this.dashboardData = await getdashboardreport();
        console.log("Données du tableau de bord :", this.dashboardData);

        if (this.dashboardData) {
          this.pendingCount = this.dashboardData.pending.count || 0;
          this.overdueCount = this.dashboardData.payment_overdue.count || 0;
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      } finally {
        this.loading = false;
      }
    },
  }
};




</script>

<style scoped>
.items {
  margin-top: 5vh;
}

.btn {
  height: 55px;
  min-width: 150px;
  background: #111c44;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  margin-top: -20px;
}


.dropdown-menu {
  width: 250px;
  background: #1a2547;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0;
  border-radius: 4px;
}

.dropdown-item {
  padding: 12px 20px;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease;
}


#filter {
  width: max-content;
  margin-top: 30px;
  margin-left: auto;
  border: 1px solid rgba(255, 255, 255, 0.1);
 
  z-index: 2;

}

#filter .dropdown .dropdown-menu {
  width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0;
  border-radius: 0;



}

#filter .dropdown .dropdown-menu li {
  padding: 10px 20px;
  width: auto;
  color: white;

}

#filter .dropdown .icon-filter {

  transform: translateX(-10px);
  font-weight: bold;

}

#filter .dropdown .dropdown-menu .li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  transition: 0.3s;
}

#filter .dropdown .dropdown-menu li:hover {
  background: rgba(255, 255, 255, 0.1);
}

#filter .dropdown .btn {
  height: 55px;
  width: 150px;
  margin-top: -30px;
  font-size: 15px;
  font-weight: bold;

}

.items .lg1 {
  display: block;
  transform: translateY(5px);
  content: "";
  height: 15px;
  width: 15px;
  border-radius:2px;
   
}

.items .lg2 {
  display: block;
  transform: translateY(5px);
  content: "";
  height: 15px;
  width: 15px;
  border-radius:2px;
   

}

#pend{
  padding: 2px 4px;
  font-size: 10px;
  border-radius:2px;
}

#sett{
    padding: 2px 4px;  
    font-size: 12px;
    border-radius:2px; 
  }



  .icon-alert {
  color: black;
  padding: 1px 4px;
  border-radius: 50%;
  


}

 


</style>
