<template>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="card">
        <div class="modal-header mb-4" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
          <h6 class="modal-title" style="margin-left: 6%;">{{ $t('absence.period') }}</h6>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body border-0">
          <div class="tab-content mt-3 mb-4">
            <div class="container">
              <form>
                <div class="container">
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">{{ $t('absence.hours_number') }}<em style="color: #f60000">*</em></label>
                      <input id="input" type="number" class="form-control" v-model="number_of_hours" :class="{ 'is-invalid': formSubmitted && !number_of_hours }"
                        style="height: 50px;" min="1" :placeholder="$t('absence.enter_hours')">
                      <div class="invalid-feedback d-block" v-if="formSubmitted && !number_of_hours">
                        {{ $t('absence.enter_number') }}
                      </div>
                    </div>

                    <div class="mb-3 text-start col-md-6">
                      <label for="weekSelect" class="form-label">
                        {{ $t('absence.period_label') }}<em style="color: #f60000">*</em>
                      </label>
                      <select class="form-select" v-model="absence_period" :class="{ 'is-invalid': formSubmitted && !number_of_hours }" id="input"
                        style="height: 50px; background-color: transparent; color: white;">
                        <option value="" style="background-color: #1a1a1a; color: white;">{{ $t('absence.select_period') }}</option>
                        <option v-for="week in weekOptions" :key="week.value" :value="week.value"
                          style="background-color: #1a1a1a; color: white;">
                          {{ $t('absence.week_label', { count: week.value }) }}
                        </option>
                      </select>
                      <div class="invalid-feedback d-block" v-if="formSubmitted && !absence_period">
                        {{ $t('absence.select_period_error') }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
          <argon-button type="button" class="btn" title="{{ $t('button.close') }}" data-bs-dismiss="modal" @click="annuler"
            style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);">
            <span>{{ $t("button.cancel") }}</span>
          </argon-button>
          <argon-button @click="sendAlert" type="button" class="btn me-5" color="success" variant="gradient"
            style="width: 200px; height: 50px; padding-top: 15px" :loading="loading"
            :active="buttonActive || showConfirmation" :clickEvent="button">
            <span v-if="!loading">{{ $t("button.send") }}</span>
          </argon-button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ArgonButton from "@/components/ArgonButton";
import { postNoteAvailable } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: 'Modal_Absence',
  components: {
    ArgonButton
  },
  data() {
    return {
      absence_period: '',
      number_of_hours: '',
      loading: false,
      buttonActive: false,
      showConfirmation: false,
      selectedMatieres: [],
      formSubmitted: false,
      school_id:'',
      weekOptions: [
        { value: 1, label: '1 Semaine' },
        { value: 2, label: '2 Semaines' },
        { value: 3, label: '3 Semaines' },
        { value: 4, label: '4 Semaines' }
      ]
    }
  },


  props: {
     
    registrationNumber: {
    type: String,
    required: true
  }

  },

  mounted() {
    this.school_id = localStorage.getItem("schoolId");
     
  },

  methods: {

    annuler() {
            this.absence_period = '';
            this.number_of_hours = "";
            this.formSubmitted= false,
            this.selectedMatieres = [];
        },

        

    async sendAlert() {
      this.formSubmitted = true;

      if (this.loading) {
        return;
      }


       if(!this.absence_period && !this.number_of_hours ){
       return
       }
       
      this.loading = true;
      this.button = "disabled";
      this.buttonActive = true;

      try {
        const data = {
          school_id: this.school_id,
          code: 4104,
          registration_number_list: [this.registrationNumber],
          number_of_hours: this.number_of_hours,
          absence_period: this.absencePeriodLabel,


        };

        const response = await postNoteAvailable(data);

        if (response.status === 200) {
          if (!this.showConfirmation) {
            this.showConfirmation = true;
            const message = this.$t("absence.success_message");
              toast.success(message, {
                autoClose: 3000,
              });
            

          }
          this.annuler();
        }
      } catch (error) {
        toast.error("Erreur lors de l'envoi de la notification", {
          autoClose: 3000,
        });
      } finally {
        this.loading = false;
        this.button = "";
        this.buttonActive = false;
        this.showConfirmation = false;
      }
    }

  },
  computed: {
    isFormValid() {
      return this.selectedWeeks && this.absenceHours > 0
    },
    absencePeriodLabel() {
      const selectedOption = this.weekOptions.find(
        (week) => week.value === this.absence_period
      );
      return selectedOption ? selectedOption.label : '';
    },
  }
}
</script>

<style scoped>
.modal.show {
  display: block;
}

.modal-lg {
  max-width: 800px;
}

.form-select:focus,
.form-control:focus {
  border-color: #75acff;
  box-shadow: 0 0 0 0.2rem rgba(117, 172, 255, 0.25);
}
.is-invalid {
    border-color: #dc3545 !important;
    border-width: 1px;
}
</style>