<template>
  
    <div class="modal-dialog">
        <div class="card">
            <div class="modal-content">
                <div class="modal-header" style="border-bottom: 1px solid rgba(255, 255, 255, 0.4)">
                    <h6 class="modal-title" id="staticBackdropLabel10" style="margin-left: 7%">
                        {{ $t("temporary_suspension.title") }}
                    </h6>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        @click="annuler"></button>
                </div>
                <div class="modal-body text-center pb-4">
                    <div class="container mb-4">
                        <form class="mx-auto mt-3" style="width:91%;" @submit.prevent="sendAlert">
                            <div class="mb-3 text-start">
                                <label for="matieres" style="font-size:12px" class="form-label">
                                    {{ $t("temporary_suspension.label1") }}<em style="color: #f60000">*</em>
                                </label>

                                <div class="row mt-3 ps-3">
                                    <div v-for="item in GRADE_CODE" :key="item.code" :value="item.name"
                                        class="form-check col-3 mt-2">
                                        <input class="form-check-input" type="checkbox" :id="`matiere-${item.id}`"
                                            :value="item.name" v-model="selectedMatieres"
                                            @change="fetchRegistrationNumbers"
                                            :class="{ 'is-invalid': formSubmitted && selectedMatieres.length === 0 }">
                                        <label class="form-check-label" :for="`matiere-${item.id}`">
                                            {{ item.name }}
                                        </label>
                                    </div>
                                    <div class="invalid-feedback d-block"
                                        v-if="formSubmitted && selectedMatieres.length === 0">
                                        V{{ $t("temporary_suspension.error1") }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mb-3 col-6 text-start">
                                    <label for="dateDebut" class="form-label">{{ $t("temporary_suspension.label2") }}<em
                                            style="color: #f60000">*</em></label>
                                    <input style="height:50px;" type="date" class="form-control" :class="{
                                        'is-invalid': (formSubmitted && !start_date) || !isDateValid
                                    }" id="input" v-model="start_date">
                                    <div class="invalid-feedback d-block" v-if="formSubmitted && !start_date">
                                        {{ $t("temporary_suspension.label2") }}
                                    </div>
                                    <div class="invalid-feedback d-block" v-if="!isDateValid">
                                        {{ $t("temporary_suspension.error") }}
                                    </div>
                                </div>

                                <div class="mb-3 col-6 text-start">
                                    <label for="input" class="form-label"> {{ $t("temporary_suspension.label3") }} <em
                                            style="color: #f60000">*</em></label>
                                    <input style="height:50px;" :class="{ 'is-invalid': formSubmitted && !end_date }"
                                        type="date" class="form-control" id="input" aria-describedby="dateFinHelp"
                                        v-model="end_date">

                                    <div class="invalid-feedback d-block" v-if="formSubmitted && !end_date">
                                        {{ $t("temporary_suspension.error3") }}
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 text-start">
                                <label for="motif" class="form-label"> {{ $t("temporary_suspension.label4") }} <em
                                        style="color: #f60000">*</em></label>
                                <textarea :class="{ 'is-invalid': formSubmitted && !suspension_reason }"
                                    class="form-control" id="input" rows="3" 
                                     :placeholder="$t('temporary_suspension.placeholder')"
                                    v-model="suspension_reason"></textarea>
                                <div class="invalid-feedback d-block" v-if="formSubmitted && !suspension_reason">
                                    {{ $t("temporary_suspension.error4") }}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer" style="border-top: 1px solid rgba(255, 255, 255, 0.4)">
                    <argon-button type="button" class="btn" title="Fermer" data-bs-dismiss="modal"
                        style="width: 200px; height: 50px; padding-top: 15px; background: none; border: 1px solid rgba(255, 255, 255, 0.4);"
                        @click="annuler">
                        <span>{{ $t("button.cancel") }}</span>
                    </argon-button>
                    <argon-button type="button" class="btn me-6" color="success" variant="gradient"
                        style="width: 200px; height: 50px; padding-top: 15px" :disabled="!isChoiceMade"
                        @click="sendAlert" :loading="loading" :active="buttonActive || showConfirmation"
                        :clickEvent="button">

                        <span v-if="!loading">{{ $t("button.send") }}</span>
                    </argon-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton";
import { postNoteAvailable, getGradeCode, getAlertSubscribers } from "@/utils/services";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";


export default {
    name: 'Stop-Course-Modal',
    components: {
        ArgonButton,

    },
    data() {
        return {
            showPending: false,
            showSettled: false,
            selectedStatus: '',
            isChoiceMade: false,
            loading: false,
            classroomId: null,
            showAlert: false,
            formSubmitted: false,
            grade_code: '',
            grade_name: '',
            GRADE_CODE: [],
            buttonActive: false,
            showConfirmation: false,
            selectedMatieres: [],
            suspension_reason: '',
            start_date: '',
            end_date: '',
            registrationNumber: [],
            school_id: '',
        };
    },
    watch: {
        selectedMatieres() {
            this.isChoiceMade = this.selectedMatieres.length > 0;
            this.fetchRegistrationNumbers();
        }
    },


    computed: {
        isDateValid() {
            if (this.start_date && this.end_date) {
                return new Date(this.start_date) < new Date(this.end_date);
            }
            return true;
        }
    },


    mounted() {
        this.school_id = localStorage.getItem("schoolId");

    },
    methods: {
        annuler() {
            this.selectedMatieres = [];
            this.suspension_reason = "";
            this.start_date = "";
            this.end_date = "";
            this.formSubmitted = false;
        },
        async getData() {
            this.loading = true;
            let grades = await getGradeCode();
            this.GRADE_CODE = grades.data;
            this.grade_code = grades.data[0].name;
            this.grade_name = grades.data[0].name;
            this.loading = false;
        },
        async fetchRegistrationNumbers() {

            try {
                const response = await getAlertSubscribers(this.selectedMatieres.join(','), '', 'ESKAALERT');

                if (response.status === 200) {
                    this.registrationNumber = response.data;
                } else {
                    toast.error("Erreur lors de la récupération des matricules des élèves", {
                        autoClose: 3000,
                    });
                }
            } catch (error) {
                toast.error("Erreur lors de la récupération des matricules des élèves", {
                    autoClose: 3000,
                });
            }
        },
        async sendAlert() {
            this.formSubmitted = true;

            if (this.loading) {
                return;
            }

            if (!this.start_date || !this.end_date || !this.suspension_reason) {
                return;
            }

            this.loading = true;
            this.buttonActive = true;

            try {
                const data = {
                    school_id: this.school_id,
                    code: 4101,
                    registration_number_list: this.registrationNumber,
                    start_date: this.start_date,
                    end_date: this.end_date,
                    suspension_reason: this.suspension_reason,
                    list_grade: this.grade_code
                };

                const response = await postNoteAvailable(data);

                if (response.status === 200) {
                    if (!this.showConfirmation) {
                        this.showConfirmation = true;
                        const message = this.$t("temporary_suspension.success_message");
                        toast.success(message, {
                            autoClose: 3000,
                        });
                         
                        this.annuler();
                    }
                }
            } catch (error) {
                toast.error("Erreur lors de l'envoi de la notification", {
                    autoClose: 3000,
                });
            } finally {
                this.loading = false;
                this.buttonActive = false;
                this.showConfirmation = false;
            }
        }
    },
    beforeMount() {
        this.getData();
    },
};
</script>

<style>
#pending,
#settled {
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    border: 1px solid green;
}

#alert-modal {
    border: 1px solid #83454d;
    color: #83454d;
    background: rgba(0, 0, 0, 0.6)
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.is-invalid {
    border-color: #dc3545 !important;
    border-width: 1px;
}
</style>
